import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
    LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION,
    listProvidersForHousehold,
} from 'actions/provider/listProvidersForHousehold';
import {
    clearIdeonProviderPlans,
    getIdeonProviderPlans,
} from 'actions/shopping/getIdeonProviderPlans';
import { ProviderSource, UserStatus } from 'api/generated/enums';
import { IIdeonProvider } from 'api/generated/models';
import Checkbox from 'components/Checkbox';
import InformationIconTooltip from 'components/InformationIconTooltip';
import CreatableAutocomplete from 'components/creatableAutocomplete/CreatableAutocomplete';
import useIdeonProviderAutocomplete from 'components/creatableAutocomplete/useIdeonProviderAutocomplete';
import useModalState from 'hooks/useModalState';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import {
    clearSelectedProviders,
    setSelectedProviders,
    setShowProviderDisclaimerModal,
} from 'pages/shop/shoppingActions';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { IIdeonProviderWithSource } from 'reducers/ideonProviders';
import { hasCompletedRequest } from 'selectors/activity';
import { isRhSelector } from 'selectors/role';
import { arrayHasValue, hasValue } from 'utilities';
import { convertProviderToIdeonProvider } from 'utilities/providerSearch';

const PROVIDER_PREPOPULATE_CUTOFF = 5;

const ProviderFilterContent = () => {
    const dispatch = useThunkDispatch();
    const { user, userId, address } = useUserProps();
    const {
        hasAcknowledgedProviderDisclaimer,
        hasLoadedProviders,
        hhmProviders,
        isRh,
        selectedProviders,
    } = useSelector((state: AppStore) => ({
        hasAcknowledgedProviderDisclaimer: state.shoppingState.hasAcknowledgedProviderDisclaimer,
        hasLoadedProviders: hasCompletedRequest(state, LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION),
        hhmProviders: state.householdProviders,
        isRh: isRhSelector(state),
        selectedProviders: state.shoppingState.selectedProviders,
    }));
    const {
        closeModal: closeDisclaimerModal,
        isVisible: isDisclaimerModalVisible,
        openModal: openDisclaimerModal,
    } = useModalState();
    const [filteredProviders, setFilteredProviders] = useState<IIdeonProvider[]>([]);
    const [checkedProviders, setCheckedProviders] = useState<number[]>([]);
    const [hasAcknowledgedDisclaimer, setHasAcknowledgedDisclaimer] = useState<boolean>(
        isRh || hasAcknowledgedProviderDisclaimer
    );
    const isRenewing = user?.status === UserStatus.Renewing;
    const year = user?.activeDate?.getYear() ?? 0;

    const handleCloseDisclaimerModal = () => {
        dispatch(setShowProviderDisclaimerModal());
        setHasAcknowledgedDisclaimer(true);
        closeDisclaimerModal();
    };

    const handleProviderCheckboxChange = useCallback(
        (provider: IIdeonProvider, isChecked: boolean) => {
            if (isChecked) {
                setCheckedProviders(checkedProviders.filter((x) => x !== provider.id));
                dispatch(clearIdeonProviderPlans(`${provider.id}`));
                dispatch(
                    setSelectedProviders(
                        selectedProviders.filter(
                            (x: IIdeonProviderWithSource) => x.id !== provider.id
                        )
                    )
                );
            } else {
                setCheckedProviders([...checkedProviders, provider.id]);
                dispatch(getIdeonProviderPlans(`${provider.id}`, userId, year));
                dispatch(setSelectedProviders([...selectedProviders, provider]));
            }
        },
        [checkedProviders, dispatch, selectedProviders, userId, year]
    );

    const renderProviderCheckboxes = useCallback(
        () =>
            filteredProviders.map((p) => {
                const checked = checkedProviders.contains(p.id);
                const providerLabel = p.presentationName as string;

                return (
                    <Checkbox
                        checked={checked}
                        key={p.id}
                        label={providerLabel}
                        name={p.presentationName}
                        onChange={() => handleProviderCheckboxChange(p, checked)}
                    />
                );
            }),
        [checkedProviders, filteredProviders, handleProviderCheckboxChange]
    );

    const creatableAutocompleteProps = useIdeonProviderAutocomplete({
        year,
        onChange: (_event, values, reason, details) => {
            if (hasValue(details?.option.id) && reason === 'selectOption') {
                if (hasValue(filteredProviders.find((ip) => ip.id === details?.option.id))) {
                    setCheckedProviders([...checkedProviders, details?.option.id as number]);
                }
                dispatch(getIdeonProviderPlans(`${details?.option.id}`, userId, year));
            } else if (reason === 'removeOption') {
                setCheckedProviders(
                    checkedProviders.filter(
                        (providerId: number) => providerId !== details?.option.id
                    )
                );
                dispatch(clearIdeonProviderPlans(`${details?.option.id}`));
            } else if (reason === 'clear') {
                setCheckedProviders([]);
                dispatch(clearIdeonProviderPlans());
            }
            dispatch(setSelectedProviders(values));
        },
        value: selectedProviders,
        zipCode: address?.zip as string,
    });

    useEffect(() => {
        if (year > 0) {
            dispatch(listProvidersForHousehold(userId, year));
        }
    }, [dispatch, userId, year]);

    useEffect(() => {
        const hhmIdeonProviders = hhmProviders.map(convertProviderToIdeonProvider);
        const filteredHhmProviders = [] as IIdeonProvider[];
        const initialSelectedProviders: IIdeonProvider[] = [];
        const initialSelectedProviderIds: number[] = [];
        hhmIdeonProviders.forEach((ip) => {
            if (!hasValue(filteredHhmProviders.find((p) => p.id === ip.id))) {
                filteredHhmProviders.push(ip as IIdeonProvider);
                if (filteredHhmProviders.length <= PROVIDER_PREPOPULATE_CUTOFF) {
                    initialSelectedProviders.push(ip as IIdeonProvider);
                    initialSelectedProviderIds.push(ip.id as number);
                    if (ip.providerSource !== ProviderSource.User) {
                        dispatch(getIdeonProviderPlans(`${ip.id}`, userId, year));
                    }
                }
            }
        });
        setCheckedProviders(initialSelectedProviderIds);
        setFilteredProviders(filteredHhmProviders);
        dispatch(setSelectedProviders(initialSelectedProviders));
    }, [dispatch, hhmProviders, isRenewing, userId, year]);

    useEffect(() => {
        if (
            !hasAcknowledgedDisclaimer &&
            hasLoadedProviders &&
            (arrayHasValue(hhmProviders) || arrayHasValue(selectedProviders))
        ) {
            openDisclaimerModal();
        }
    }, [
        hasAcknowledgedDisclaimer,
        hasLoadedProviders,
        hhmProviders,
        isRh,
        openDisclaimerModal,
        selectedProviders,
    ]);

    useEffect(
        () => () => {
            dispatch(clearIdeonProviderPlans());
            dispatch(clearSelectedProviders());
        },
        [dispatch]
    );

    return (
        <React.Fragment>
            <Stack marginY={2}>
                <Stack marginBottom={1}>
                    <Typography py={1} sx={{ color: '#374958' }} variant="h4">
                        Search For Providers{' '}
                        <InformationIconTooltip title="Search for doctors, specialists, or hospitals to see if they are covered for each plan" />
                    </Typography>
                    <CreatableAutocomplete
                        freeSolo={false}
                        {...creatableAutocompleteProps}
                        helperText=""
                        label=""
                    />
                </Stack>
                {hhmProviders.length > 0 && (
                    <Accordion
                        disableGutters
                        sx={{ background: 'none', border: 'none', boxShadow: 'none' }}
                    >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography fontWeight={600}>
                                Your Providers ({filteredProviders.length})
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{renderProviderCheckboxes()}</AccordionDetails>
                    </Accordion>
                )}
            </Stack>
            {isDisclaimerModalVisible && (
                <Dialog fullWidth maxWidth="sm" open sx={{ padding: 3, zIndex: 98 }}>
                    <DialogContent>
                        <Stack spacing={2} sx={{ p: 2 }}>
                            <Typography variant="body2">
                                While looking for plans, you can use our provider search to see if
                                specific doctors or hospitals are covered.
                            </Typography>
                            <Typography variant="body2">
                                Whether specific doctors, hospitals, or prescriptions are covered
                                changes often, so we cannot guarantee their coverage. As best
                                practice, we recommend calling your doctor to see if they accept the
                                plan, and checking with the plan carrier directly for prescription
                                coverage.
                            </Typography>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ direction: 'row', justifyContent: 'center' }}>
                        <Button
                            onClick={handleCloseDisclaimerModal}
                            sx={{ mb: 2 }}
                            variant="contained"
                        >
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

export default hot(module)(ProviderFilterContent);
